import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { MDTApp } from 'mdt-core-components/src/actions/bootAction.js';
import { MDTApi } from 'mdt-core-components/src/services/mdtapi.js';
import { BrowserRouter } from 'react-router-dom';
import { startListener } from 'mdt-core-components/src/listeners/history.js';
import { Spinner } from 'mdt-core-components';
import configureStore from './configureStore.js';
import App from './components/App.js';
import './index.css';

const history = createBrowserHistory();
const store = configureStore(history);
const root = createRoot(document.getElementById('root'));

// TODO: Look for a better place to init this, but this looks ok for now (testing) 20231017:Alevale
const settings = {
    buid: 'MDT',
    appid: 'portal',
    basesiteid: 'mdtb2bsite',
};

MDTApp.registerSettings(settings);
MDTApi.registerStore(store);

startListener(history, store);
const render = (App) =>
    new Promise(function (resolveStore) {
        // TODO: Refactor the init bootstrap to have the minimal amount of API's necessary
        //  (until this function hasn't finished there's nothing rendered in the page, so if an API
        //  call takes 20s we have 20s of an empty page) which is far from ideal 20240801:Alevale
        store.dispatch(MDTApp.initBootstrap(resolveStore));
        // NOTE: While we are waiting at least show a spinner 20240801:Alevale
        root.render(
            <Spinner
                {...{
                    showLoading: true,
                    browserSupportsAnimations: true,
                }}
            />
        );
    })
        .then(function () {
            root.render(
                <Provider store={store}>
                    <BrowserRouter basename='/'>
                        <App />
                    </BrowserRouter>
                </Provider>
            );
        })
        .catch((error) => {
            console.trace();
            console.log('Error while bootstrapping', error);
            console.trace(error);
        });

render(App);
