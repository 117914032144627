import querySerializer from 'query-string';
import { MDTAnalytics } from 'mdt-core-components/src/services/mdtanalytics.js';
import jwt_decode from 'jwt-decode';
import constants from 'mdt-core-components/src/helpers/constants.js';
import { redirect } from 'redux-first-router';

let mustchange = true;
// The purpose of the below options is to demonstrate auth filtering.
// onBeforeChange fires before going to a new route, and you can
// redirect if certian conditions aren't met.

export default {
    querySerializer,
    onBeforeChange: (dispatch, getState, action) => {
        const {
            location: { routesMap },
        } = getState();

        // Capturing initial time of any page (Components) load
        window.pageLoadStartTime = new Date().getTime();

        const { type } = action.action;
        const isLogin = getState().meDetails.meState.LOGGED_IN;

        if (isLogin) {
            if (type !== 'USERPREFERENCES') {
                mustchange = true;
            }
            const decodedAccessToken = jwt_decode(localStorage.getItem('apiToken'));
            const isInternal = decodedAccessToken.isinternal || '';
            if (
                decodedAccessToken.password === constants.PASSWORD_MUST_CHANGE &&
                mustchange &&
                isInternal !== 'True'
            ) {
                mustchange = false;
                const payload = {};
                payload[constants.PASSWORD_MUST_CHANGE] = true;
                const redirectAction = redirect({ type: 'USERPREFERENCES', payload });
                dispatch(redirectAction);
            }
        }

        // Tracking Pages on Route change.
        MDTAnalytics.track({
            pageInfo: {
                pageName: type,
                pagePath: routesMap[type],
                widgetName: 'N/A',
            },
        });
    },
    onAfterChange: () => {},
};
