import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Switcher = ({ page }) => {
    const UniversalComponent = lazy(() => import(`./${page}/index.js`));

    return (
        <Suspense
            fallback={
                <div className='spinner'>
                    <div />
                </div>
            }
        >
            <UniversalComponent />
        </Suspense>
    );
    // NOTE: After a call with Danny this doesn't really add much, but I'm leaving it here for now.  20231025:Alevale
    // return <TransitionGroup className={`switcher ${direction}`}>
    //     <CSSTransition key={page} timeout={0}>
    //       <Suspense
    //         fallback={
    //           <div className="spinner">
    //             <div />
    //           </div>
    //         }
    //       >
    //         <UniversalComponent />
    //       </Suspense>
    //     </CSSTransition>
    // </TransitionGroup>
};

const isListFetching = (page) => {
    return page === 'List';
};

const mapState = ({ page, direction }) => {
    const isLoading = isListFetching(page);
    return { page, direction, isLoading };
};

export default connect(mapState)(Switcher);
