const initState = {
    services: [],
    countries: [],
};

const SET_SERVICES = 'SET_SERVICES';
const SET_COUNTRIES = 'SET_COUNTRIES';

export const setServices = (data) => ({ type: SET_SERVICES, payload: data });
export const setCountries = (data) => ({ type: SET_COUNTRIES, payload: data });

export default (state = initState, action) => {
    switch (action.type) {
        case SET_SERVICES:
            return { ...state, services: action.payload };
        case SET_COUNTRIES:
            return { ...state, countries: action.payload };
        default:
            return state;
    }
};
