import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { connectRoutes } from 'redux-first-router';
import thunk from 'redux-thunk';
import routesMap from './routesMap.js';
import options from './options.js';
import reducers from './reducers/index.js';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default () => {
    const middlewares = [thunk];

    const { reducer, middleware, enhancer } = connectRoutes(routesMap, {
        ...options,
    });
    middlewares.push(middleware);

    const rootReducer = combineReducers({ ...reducers, location: reducer });

    const enhancers = composeEnhancers(enhancer, applyMiddleware(...middlewares));

    return createStore(rootReducer, enhancers);
};
